<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            申请编号：
            <el-input
              v-model="parameter.expenseNumber"
              @change="dataUpdate"
              class="ipt_width expense__width"
              placeholder="请输入申请编号"
              clearable
            >
            </el-input>
            申请人：
            <el-input
              v-model="parameter.userName"
              @change="dataUpdate"
              class="ipt_width"
              placeholder="请输入申请人"
              clearable
            >
            </el-input>
            费用所属部门：
            <el-select
              v-model="parameter.deptId"
              clearable
              filterable
              @change="dataUpdate"
              placeholder="请选择费用所属部门"
              class="ipt_width"
            >
              <el-option
                v-for="item in deptList"
                :key="item.id"
                :label="item.deptName"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="申请日期（始）"
              end-placeholder="申请日期（末）"
            >
            </el-date-picker>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">申请流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED" label="未审核"></el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS" label="已审核"></el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT" label="不通过"></el-tab-pane>
          </el-tabs>
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column prop="expenseNumber" label="申请编号" align="center"></el-table-column>
            <el-table-column
              prop="createName"
              label="申请人"
              align="center"
              width="120"
            ></el-table-column>
            <el-table-column prop="deptName" label="费用所属部门" align="center"></el-table-column>
            <el-table-column
              prop="companyTypeName"
              label="费用所属单位"
              align="center"
            ></el-table-column>
            <el-table-column prop="sumCost" label="申请金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.sumCost }}
              </template>
            </el-table-column>
            <el-table-column prop="projectBudget" label="申请时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="statusName" label="状态" align="center"> </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status >= 2"
                  type="text"
                  size="small"
                  @click.stop="handleDetails(scope.row)"
                  >详情</el-button
                >
                <el-button type="text" size="small" @click.stop="handleEdit(scope.row)"
                  >审核</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="公共费用报销预申请流程" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/公共费用报销预申请.png" alt="公共费用报销预申请流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit v-if="editShow" :isShow.sync="editShow" :options="form" :getTitle="sonTitle"></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../edit/Edit.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        approvalType: 'UNDETERMINED',
        expenseNumber: '', //申请编号
        userName: '', //申请人姓名
        deptId: '', //部门id
        startDate: '', //查询开始时间
        endDate: '', //查询结束时间
      },
      deptList: [],
      createdDate: [],
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      editDialogLoading: false,
      editShow: false,
      drawer: false,
      companyTypeList: [], //公司所属单位列表
      sonTitle: '',
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getCompanyList()
    this.getTableData()
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },

  watch: {
    'parameter.approvalType': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getTableData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },

  methods: {
    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .listDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.expenseNumber = null
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.pageNow = 1
      this.createdDate = []
      this.getTableData() /** 加载 "付款申请审核" 数据 **/
    },
    dataUpdate() {
      this.$forceUpdate()
    },

    /** 加载 数据 **/
    getTableData() {
      this.loading = true
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.$api.publicSpendingExpense
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          // 根据companyType查公司所属单位
          res.data.records.forEach(item => {
            const findCompanyType = this.companyTypeList.find(
              companyTypeItem => companyTypeItem.dictVal == item.companyType
            )
            if (findCompanyType) {
              item.companyTypeName = findCompanyType.dictName
            }
          })
          this.tableData = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**
     * 更改状态
     * @param val     当前值
     */
    handleStatus(val) {
      this.parameter.approvalType = val
      this.getTableData()
    },

    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.form = {
        list_s: 2,
        ...item,
      }
      this.sonTitle = '预申请详情'
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 审核" 操作
     * @param item      当前数据
     */
    handleEdit(item) {
      this.form = {
        detailType: 1, //* DetailType用来标识详情的类型，0代表填报详情，1代表审核详情
        list_s: 2,
        ...item,
      }
      this.sonTitle = '预申请详情'
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.form = {}
      this.getTableData()
      this.editShow = false
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return ''
      }
    },
  },
}
</script>

<style>
.mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 93px);
}

.status {
  margin-bottom: 15px;
}
.row {
  display: flex;
  flex-direction: column;
}
</style>
